/* eslint-disable react/jsx-no-target-blank */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import './Map.css';
import mapboxgl from 'mapbox-gl';
import { facts } from './madisonFacts.js';
import { ReactComponent as Arrow } from './expand.svg';
import { ReactComponent as Close } from './close.svg';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

const date = new Date()
const dateString = date.toISOString().substring(0, 10);

const buttonStyle = {
  backgroundColor: "#ce1337",
  opacity: "80%",
  padding: "8px",
  borderRadius: "8px",
  border: "1px solid #ccc",
  cursor: "pointer",
  margin: "8px 0px",
  textAlign: "center",
  width: "fit-content",
  color: 'white',
  fontSize: '16px'
};

const Map = () => {
  // STATE VARIABLES
  const mapContainerRef = useRef();
  const mapRef = useRef();
  const [eventData, setEventData] = useState([]);
  const [allEventData, setAllEventData] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [moreResults, setMoreResults] = useState(true);
  const [dateChosen, setDateChosen] = useState(dateString);
  const [markerArray, setMarkerArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showEventList, setShowEventList] = useState(true);
  const [shouldShowInfoModal, setShouldShowInfoModal] = useState(false);
  const [shouldShowWelcomeModal, setShouldShowWelcomeModal] = useState(true);
  const getRandomMadisonFact = () => {
    const randomIndex = Math.floor(Math.random() * facts.length);
    return facts[randomIndex];
  }
  const [randomMadisonFact, setRandomMadisonFact] = useState(getRandomMadisonFact());

  // UTILITY FUNCTIONS
  const getUrlExtensionFromEvent = (evt) => {
    const url = evt.html.split('<a href="https://isthmus.com/events/')
    if (url.length < 2) {
      return '';
    }
    const url2 = url[1].split('">')
    return url2[0];
  }

  const getDateTimeStringFromEvent = (evt) => {
    const dateTime = evt.html.split('event_date">')
    if (dateTime.length < 2) {
      return '';
    }
    const dateTime2 = dateTime[1].split('</p>')
    return dateTime2[0];
  }

  const getLocationStringFromEvent = (evt) => {
    const location = evt.html.split('/locations/')
    if (location.length < 2) {
      return '';
    }
    const location2 = location[1].split('">')
    const location3 = location2[1].split('</a>')
    return location3[0].replaceAll('&amp;', '&');
  }

  const getDetailsStringFromEvent = (evt) => {
    const details = evt.html.split('description">')
    if (details.length < 2) {
      return '';
    }
    const details2 = details[1].split(' <a')
    return details2[0].replaceAll('&amp;', '&');
  }

  // GET DATA FROM ISTHMUS
  const getIsthmusData = useCallback(async (date, page) => {
    setIsLoading(true);
    setRandomMadisonFact(getRandomMadisonFact());
    setEventData([]);
    const urlBase = process.env.NODE_ENV === 'development'
      ? 'http://localhost:8888'
      : 'https://mad-map.netlify.app';
    let events = await fetch(`${urlBase}/.netlify/functions/get-events?dateString=${date}&pageNumber=${page}`);
    const response = await events.json();
    setMoreResults(response.more);
    const results = response.results.filter((evt) => !!evt.lat && !!evt.lng);
    const allEvents = allEventData;
    allEvents[date] = allEvents[date] || {};
    allEvents[date][page.toString()] = results;
    setAllEventData(allEvents);
    setEventData(results);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SET MAP MARKERS BY PARSING EVENT DATA
  const setMapMarkers = useCallback(() => {
    if (eventData.length) {
      markerArray.forEach((m) => {
        m.marker.remove();
        m.popup.remove();
      });
      setMarkerArray([]);
      let newMarkerArray = [];
      eventData.forEach((evt, i) => {
        const urlExtension = getUrlExtensionFromEvent(evt);
        const dateTimeString = getDateTimeStringFromEvent(evt);
        const locationString = getLocationStringFromEvent(evt);
        const detailsString = getDetailsStringFromEvent(evt);
        const markerLocation = [parseFloat(evt.lng), parseFloat(evt.lat)];

        const popup = new mapboxgl.Popup({ offset: 25, className: 'popup' }).setHTML(`
          <a style='text-decoration:underline;color:blue;font-weight:bold;' href='https://isthmus.com/events/${urlExtension}' target='blank'>${evt.title}</a>
          <br>
          <p style='font-weight:bold;'>${locationString}</p>
          <p style='font-weight:bold;'>${dateTimeString}</p>
          <p>${detailsString}</p>
        `).setMaxWidth('200px');
        
        const marker = new mapboxgl.Marker({ scale: 0.7, color: '#ce1337', cursor: 'pointer' })
          .setLngLat(markerLocation)
          .setPopup(popup)
          .addTo(mapRef.current);
        const markerDiv = marker.getElement();
        markerDiv.addEventListener('mouseenter', () => {
          markerDiv.querySelectorAll('svg path[fill="' + marker._color + '"]')?.[0].setAttribute("fill", '#ed3b5e');
          markerDiv.style.cursor = 'pointer';
          marker._color = '#ed3b5e';
        });
        markerDiv.addEventListener('mouseleave', () => {
          markerDiv.querySelectorAll('svg path[fill="' + marker._color + '"]')?.[0].setAttribute("fill", '#ce1337');
          marker._color = '#ce1337';
        });
        
        newMarkerArray.push({ marker, popup });
      });
      setMarkerArray(newMarkerArray);
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData, markerArray]);

  // RESET MARKERS WHEN DATA FETCHED
  useEffect(() => {
    if (eventData.length) {
      setMapMarkers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  // INITIALIZE MAP
  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-89.401230, 43.073051],
      zoom: 12,
      pitch: 50.00,
    });
    mapRef.current.on('load', async () => {
      await getIsthmusData(dateChosen, pageNum);
      setMapMarkers();
    });
    return () => mapRef.current.remove();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div id="map" ref={mapContainerRef} className='map-container' />
      <div style={{ 
        position: "absolute", 
        display: "flex",
        flexDirection: "column",
        top: "6px", 
        right: "6px", 
        maxHeight: "90dvh",
        width: "320px",
        backgroundColor: "#eee", 
        borderRadius: "8px", 
        padding: "12px",
        border: '1px solid #ccc',
        fontSize: "18px",
        alignContent: "center",
        alignItems: "center",
      }}>
        <div style={{ 
          alignSelf: "start", 
          justifySelf: "left", 
          flexDirection: "row", 
          display: "flex", 
          justifyItems: "space-between",
          alignItems: "center", 
          width: '100%',
          padding: '2px',
          marginBottom: '4px'
        }}>
          <p style={{ fontSize: '16px' }}>Date of events:</p>
          <input aria-label="Date" type="date" value={dateChosen} onChange={(e) => {
            setDateChosen(e.target.value);
            setPageNum(1);
            getIsthmusData(e.target.value, 1);
          }}/>
          {(!isLoading && eventData.length > 0) &&
            <div onClick={() => setShowEventList(!showEventList)} style={{ cursor: 'pointer', marginTop: '4px' }}>
              <Arrow style={showEventList ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          }
        </div>

        {isLoading && (
          <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll', marginTop: '4px' }}>
            <div style={{ justifySelf: "center", alignSelf: "center" }}><div className="lds-ripple"><div></div><div></div></div></div>
            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Madison Facts</p>
            <p style={{ fontSize: '16px' }}>{randomMadisonFact}</p>
            <button 
              onClick={() => setRandomMadisonFact(getRandomMadisonFact())}
              style={buttonStyle}
            >New fact</button>
          </div>
        )}

        {eventData.length > 0 && showEventList && (
          <div style={{ 
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            borderRadius: "4px", 
            padding: "4px 0px",
            textAlign: "left",
            fontSize: "12px",
          }}>
            {eventData.map((evt, i) => (
              <div key={i} style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                padding: '4px 8px',
                borderRadius: '4px',
                backgroundColor: '#fff',
                margin: '4px 0px',
                border: '1px solid #ccc',
                cursor: 'pointer'
              }}
              onClick={() => {
                if (markerArray.length > 0) {
                  markerArray.forEach((e) => { 
                    e.popup.remove(); 
                  });
                  const marker = markerArray[i].marker;
                  marker.togglePopup();
                }
              }}
              >
                <a 
                  style={{ textDecoration: 'underline', color: 'blue', fontWeight: 'bold' }} 
                  href={`https://isthmus.com/events/${getUrlExtensionFromEvent(evt)}`} 
                  target='blank'
                >{evt.title}</a>
                <p style={{ fontWeight: 'bold' }}>{getLocationStringFromEvent(evt)}</p>
                <p style={{ fontWeight: 'bold' }}>{getDateTimeStringFromEvent(evt)}</p>
                <p>{getDetailsStringFromEvent(evt)}</p>
              </div>
            ))}
            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              {pageNum > 1 && (
                <button 
                  style={buttonStyle}
                  onClick={() => {
                    setEventData(allEventData[dateChosen][pageNum - 1]);
                    setPageNum(pageNum - 1);
                  }}
                >Previous</button>
              )}

              {((!!allEventData[dateChosen] && allEventData[dateChosen][pageNum + 1]) || moreResults) && (
                <button 
                  style={buttonStyle}
                  onClick={() => {
                    if (allEventData[dateChosen][pageNum + 1]) {
                      setEventData(allEventData[dateChosen][pageNum + 1]);
                      setPageNum(pageNum + 1)
                    } else {
                      if (moreResults) {
                        getIsthmusData(dateChosen, pageNum + 1);
                        setPageNum(pageNum + 1)}
                      }
                    }
                  }
                >More</button>
              )}
            </div>
          </div>
        )}
        <button style={{ 
          color: '#ce1337', 
          fontSize: '18px'
        }} onClick={() => setShouldShowInfoModal(!shouldShowInfoModal)}>ⓘ</button>
      </div>
      {shouldShowInfoModal && (
        <div 
          style={{
            position: 'absolute', 
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', 
            textAlign: 'center',
            backgroundColor: '#eee',
            border: '1px solid #ccc',
            padding: '4px 12px',
            paddingTop: '4px',
            display: 'flex',
            flexDirection: 'column', 
            justifyItems: 'center',
            borderRadius: '8px',
            width: '200px'
          }}
        >
          <div onClick={() => setShouldShowInfoModal(false)} style={{ cursor: 'pointer', alignSelf: 'end', justifySelf: 'end' }}>
            <Close />
          </div>
          <p style={{ 
            fontSize: '14px', 
            marginBottom: '12px' 
          }}>Made with <span style={{ color: '#ce1337' }}>♥</span> by <a target="_blank" style={{ textDecoration: 'underline' }} href="https://github.com/tylerb1">tylerb1</a></p>
          <p style={{ 
            fontSize: '14px', 
            marginBottom: '12px' 
          }}>If you find it helpful, consider supporting <a target="_blank" style={{ textDecoration: 'underline' }} href="https://isthmus.ac-page.com/membership-page">The Isthmus</a> or <a target="_blank" style={{ textDecoration: 'underline' }} href="https://ko-fi.com/tylerb1">me</a> with some $$$.
          </p>
        </div>
      )}

      {shouldShowWelcomeModal && (
        <div 
          style={{
            position: 'absolute', 
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', 
            textAlign: 'center',
            backgroundColor: '#eee',
            border: '1px solid #ccc',
            padding: '4px 12px',
            paddingTop: '4px',
            display: 'flex',
            flexDirection: 'column', 
            justifyItems: 'center',
            borderRadius: '8px',
            width: '200px'
          }}
        >
          <div onClick={() => setShouldShowWelcomeModal(false)} style={{ cursor: 'pointer', alignSelf: 'end', justifySelf: 'end' }}>
            <Close />
          </div>
          <h1 style={{ 
            fontSize: '28px', 
            marginBottom: '12px' 
          }}>Mad Map</h1>
          
          <p style={{ 
            fontSize: '14px', 
            marginBottom: '12px' 
          }}>Welcome to Mad Map, an interactive map of upcoming events in Madison, Wisconsin.
          </p>
          
          <p style={{ 
            fontSize: '14px', 
            marginBottom: '12px' 
          }}>Made with <span style={{ color: '#ce1337' }}>♥</span> by <a target="_blank" style={{ textDecoration: 'underline' }} href="https://github.com/tylerb1">tylerb1</a>
          </p>
          
          <p style={{ 
            fontSize: '14px', 
            marginBottom: '12px' 
          }}>If you find Mad Map helpful, consider supporting <a target="_blank" style={{ textDecoration: 'underline' }} href="https://isthmus.ac-page.com/membership-page">The Isthmus</a> or <a target="_blank" style={{ textDecoration: 'underline' }} href="https://ko-fi.com/tylerb1">me</a> with some $$$.
          </p>
        </div>
      )}
    </div>
  );
};

export default Map;
